import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#0070eb",
        secondary: "#21aea7",
        accent: "#0070eb",
        border: "#E6F0FD",
        iconsHeader: "#343235",
        lgrey: '#919191'
      }
    }
  },
  icons: {
    iconfont: "fa"
  }
});
