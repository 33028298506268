<template>
  <v-container class="my-8">
    <v-row class="mb-4">
      <v-col>
        <h3>{{ $t("account.track_order") }} #{{ this.$route.params.id }}</h3>
      </v-col>
    </v-row>
    <v-row class="my-4">
      <v-card
        v-for="track in tracking.locations"
        :key="track.created_at"
        class="mb-4 px-4 py-4"
        style="background-color: #fff; width: 100%"
        flat
        rounded="2"
        elevation="1"
      >
        <v-row>
          <v-col cols="12">
            <span class="mb-1" style="font-size: 12px; display: block"
              ><span style="font-weight: bold"
                >{{ $t("account.tracking_description") }} :</span
              >
              {{ track.description_pretty }}</span
            >

            <span class="mb-1" style="font-size: 12px; display: block"
              ><span style="font-weight: bold"
                >{{ $t("account.tracking_at") }}:</span
              >
              {{ new Date(track.tracking_at_local).toDateString() }}
            </span>

            <span class="mb-1" style="font-size: 12px; display: block"
              ><span style="font-weight: bold"
                >{{ $t("account.tracking_location") }} :</span
              >
              {{ track.location }}</span
            >
          </v-col>
        </v-row>
      </v-card>
    </v-row>
    <v-row class="mb-4" v-if="tracking.locations.length <= 0">
      <v-alert outlined color="border" style="width: 100%; color: #343235">
        <span style="color: #343235">{{ $t("account.no_data") }}</span>
      </v-alert>
    </v-row>
    <!-- <v-simple-table class="my-6">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center">{{ $t("account.tracking_at") }}</th>
            <th class="text-center">{{ $t("account.tracking_location") }}</th>
            <th class="text-center">
              {{ $t("account.tracking_description") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="track in tracking.locations" :key="track.created_at">
            <th>{{ track.tracking_at_local }}</th>
            <td>{{ track.location }}</td>
            <td>{{ track.description_pretty }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table> -->
  </v-container>
</template>

<script>
export default {
  components: {
    //
  },
  data: () => ({
    tracking: null,
  }),

  created() {
    this.getTrackingData();
  },

  methods: {
    getTrackingData() {
      this.$http
        .get("/shipped/order/locations?order_id=" + this.$route.params.id)
        .then(({ data }) => {
          this.tracking = data;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.boldnes {
  font-weight: 700;
}
</style>
