<template>
  <div>
    <v-container class="my-8">
      <v-row class="mb-4">
        <p style="padding: 0; margin: 0; font-weight: 700; font-size: 14px">
          {{ $t("account.new_assisted_purchase") }}
        </p>
      </v-row>
      <v-row class="mb-4">
        <Warehouse />
      </v-row>
      <v-row class="mb-4">
        <p
          style="
            margin: 0;
            display: block;
            padding: 0;
            font-weight: 700;
            font-size: 12px;
          "
        >
          {{ $t("account.where_would_you") }}
        </p>
      </v-row>
      <v-row class="mb-8">
        <v-text-field
          class="mb-4"
          v-model="order.site_name"
          :label="$t('account.site_name')"
          outlined
          hide-details="true"
        ></v-text-field>
        <v-text-field
          v-model="order.site_url"
          :label="$t('account.site_url')"
          type="url"
          outlined
          hide-details="true"
        ></v-text-field>
      </v-row>
      <v-row class="mb-4">
        <p
          style="
            margin: 0;
            display: block;
            padding: 0;
            font-weight: 700;
            font-size: 12px;
          "
        >
          {{ $t("account.please_let_us_know") }}
        </p>
      </v-row>
      <v-row>
        <div style="width: 100%">
          <v-text-field
            class="mb-4"
            v-model="item.name"
            outlined
            hide-details="true"
            :label="$t('b4me.item_name')"
          ></v-text-field>
          <v-text-field
            class="mb-4"
            v-model="item.options"
            outlined
            :label="$t('b4me.options')"
            hide-details="true"
          ></v-text-field>
          <v-text-field
            class="mb-4"
            v-model="item.url"
            outlined
            :label="$t('b4me.item_url')"
            hide-details="true"
          ></v-text-field>
          <v-text-field
            class="mb-4"
            v-model="item.price"
            outlined
            type="number"
            :label="$t('b4me.price_per')"
            hide-details="true"
          ></v-text-field>
          <v-text-field
            class="mb-4"
            v-model="item.quantity"
            outlined
            type="number"
            :label="$t('b4me.quantity')"
            hide-details="true"
          ></v-text-field>
          <!-- <v-btn class="my-2" outlined color="red"
                      ><v-icon>fa fa-trash</v-icon></v-btn
                    > -->
        </div>
      </v-row>

      <v-row class="mb-4">
        <v-btn
          elevation="0"
          height="48"
          @click="addAnotherItem"
          color="accent"
          block
        >
          {{ $t("b4me.add_new_item") }}</v-btn
        >
      </v-row>

      <v-row v-if="order.items.length > 0">
        <v-expansion-panels>
          <v-expansion-panel v-for="(item, i) in order.items" v-bind:key="i">
            <v-expansion-panel-header>
              {{ item.name }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div>
                <v-text-field
                  class="mb-4"
                  v-model="item.name"
                  outlined
                  hide-details="true"
                  :label="$t('b4me.item_name')"
                ></v-text-field>
                <v-text-field
                  class="mb-4"
                  v-model="item.options"
                  outlined
                  :label="$t('b4me.options')"
                  hide-details="true"
                ></v-text-field>
                <v-text-field
                  class="mb-4"
                  v-model="item.url"
                  outlined
                  :label="$t('b4me.item_url')"
                  hide-details="true"
                ></v-text-field>
                <v-text-field
                  class="mb-4"
                  v-model="item.price"
                  outlined
                  type="number"
                  :label="$t('b4me.price_per')"
                  hide-details="true"
                ></v-text-field>
                <v-text-field
                  class="mb-4"
                  v-model="item.quantity"
                  outlined
                  type="number"
                  :label="$t('b4me.quantity')"
                  hide-details="true"
                ></v-text-field>
                <v-btn
                  elevation="0"
                  @click="deleteItem(item.name)"
                  color="error"
                  block
                  ><v-icon>fa fa-trash</v-icon></v-btn
                >
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>

      <v-row class="mt-8 mb-4">
        <v-text-field
          v-model="order.domestic_charging"
          :label="$t('b4me.domestic_shipping')"
          :placeholder="$t('b4me.domestic_shipping')"
          outlined
          hide-details="true"
        ></v-text-field>
      </v-row>

      <v-row class="mb-4">
        <p
          style="
            margin: 0;
            display: block;
            padding: 0;
            font-weight: 700;
            font-size: 12px;
          "
        >
          {{ $t("b4me.any_other") }}
        </p>
      </v-row>
      <v-row class="mb-4">
        <v-textarea
          outlined
          name="input-7-4"
          :label="$t('b4me.instructions')"
          hide-details="true"
        ></v-textarea>
      </v-row>
      <v-row class="mb-4" v-if="errors != null">
        <v-col cols="12">
          <v-alert outlined color="error">
            <ul>
              <li v-for="(error, i) in errors" v-bind:key="i">{{ error }}</li>
            </ul>
          </v-alert>
        </v-col>
      </v-row>
      <v-row class="mb-12">
        <v-btn
          elevation="0"
          height="48"
          @click="createOrder"
          color="accent"
          block
        >
          {{ $t("b4me.next") }}
        </v-btn>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Warehouse from "@/components/Warehouse.vue";

export default {
  components: {
    Warehouse,
  },
  data: () => ({
    order: {
      site_name: null,
      site_url: null,
      instructions: null,
      store_id: 1,
      domestic_charging: 0,
      items: [],
    },
    item: {
      name: null,
      options: null,
      url: null,
      price: null,
      quantity: 1,
    },
    errors: null,
  }),
  methods: {
    addAnotherItem() {
      this.order.items.push(this.item);
      this.item = {
        name: null,
        options: null,
        url: null,
        price: null,
        quantity: 1,
      };
    },

    deleteItem(name) {
      this.order.items = this.order.items.filter((item) => {
        return item.name != name;
      });
    },

    createOrder() {
      this.order.store_id = this.$store.state.store_id;
      if (this.item.name != null) {
        this.order.items.push(this.item);
      }
      this.$http
        .post("/buy4me", this.order)
        .then(({ data }) => {
          this.$router.push({
            name: "Details B4me",
            params: { id: data.order.id },
          });
        })
        .catch((errors) => {
          this.errors = errors.response.data.errors;
        });
    },
  },
};
</script>
