<template>
  <div class="home">
    <v-container class="my-8">
      <v-row class="mb-4">
        <p
          style="
            padding: 0;
            margin: 0;
            font-weight: bold;
            font-size: 16px;
            color: #343235;
          "
        >
          {{ $t("custom.buy4me_title") }}
        </p>
      </v-row>
      <v-row>
        <Warehouse />
      </v-row>
      <v-row class="mb-4">
        <p
          style="font-size: 12px; padding: 0; margin: 0"
          v-html="$t('account.assisted_purchase_desc')"
        ></p>
        <!-- <v-btn
          height="48"
          elevation="0"
          to="/b4me/create"
          block
          color="accent"
          >{{ $t("account.new_assisted_purchase") }}</v-btn
        > -->
      </v-row>
      <v-row class="mb-4">
        <v-btn
          height="48"
          elevation="0"
          to="/b4me/create"
          block
          color="accent"
          >{{ $t("account.new_assisted_purchase") }}</v-btn
        >
      </v-row>
      <v-row class="my-4">
        <v-card
          v-for="order in orders"
          :key="order.id"
          class="mb-4 px-4 py-4"
          style="background-color: #fff; width: 100%"
          flat
          rounded="2"
          elevation="1"
        >
          <v-row>
            <v-col cols="12">
              <span class="mb-1" style="font-size: 12px; display: block"
                ><span style="font-weight: bold"
                  >{{ $t("account.purchase_#") }} :</span
                >
                {{ order.id }}</span
              >
              <span class="mb-1" style="font-size: 12px; display: block"
                ><span style="font-weight: bold"
                  >{{ $t("account.status") }} :
                </span>
                <v-alert
                  class="text--white"
                  style="
                    color: white;
                    height: auto;
                    color: white;
                    padding: 4px 6px;
                    border-radius: 20px;
                    font-size: 12px;
                  "
                  small
                  :color="
                    order.status == 'completed'
                      ? 'green'
                      : order.status == 'submitted'
                      ? 'orange'
                      : order.status == 'ready'
                      ? 'blue'
                      : order.status == 'created'
                      ? 'orange'
                      : order.status == 'canceled'
                      ? 'red'
                      : 'blue'
                  "
                  ><span style="color: white">{{
                    $t("b4me." + order.status)
                  }}</span></v-alert
                ></span
              >
              <span class="mb-1" style="font-size: 12px; display: block"
                ><span style="font-weight: bold"
                  >{{ $t("account.date_requested") }}:</span
                >
                {{ new Date(order.date_requested).toDateString() }}
              </span>
              <span class="mb-1" style="font-size: 12px; display: block"
                ><span style="font-weight: bold"
                  >{{ $t("account.date_purchased") }} :</span
                >
                {{
                  order.status == "created" ||
                  order.status == "submitted" ||
                  order.status == "ready"
                    ? "-"
                    : new Date(order.date_purchased).toDateString()
                }}
              </span>
              <span class="mb-1" style="font-size: 12px; display: block"
                ><span style="font-weight: bold"
                  >{{ $t("account.total_purchase") }} :</span
                >
                {{ order.cost_with_currency }}</span
              >
              <span class="mb-1" style="font-size: 12px; display: block"
                ><span style="font-weight: bold"
                  >{{ $t("b4me.service_charge") }} :</span
                >
                {{ order.service_charge_with_currency }}
              </span>
              <span class="mb-1" style="font-size: 12px; display: block"
                ><span style="font-weight: bold"
                  >{{ $t("b4me.domestic_charges") }} :</span
                >
                {{ order.domestic_charge_with_currency }}
              </span>
              <span class="mb-1" style="font-size: 12px; display: block"
                ><span style="font-weight: bold">{{ $t("b4me.total") }} :</span>
                {{ order.total_with_currency }}</span
              >
            </v-col>
            <v-col cols="12">
              <v-btn
                elevation="0"
                :to="'/b4me/pay/' + order.id"
                color="accent"
                class="mx-1"
                small
                v-if="order.status == 'ready' && order.warba_status == null"
                >{{ $t("account.ready_to_pay") }}</v-btn
              >
              <v-btn
                elevation="0"
                :to="'/b4me/' + order.id"
                color="accent"
                class="mx-1"
                small
                >{{ $t("b4me.view_details") }}</v-btn
              >
              <v-btn
                elevation="0"
                :to="'/b4me/track/' + order.id"
                color="accent"
                class="mx-1"
                outlined
                small
                >{{ $t("b4me.track") }}</v-btn
              >
              <v-btn
                elevation="0"
                :to="'/b4me/edit/' + order.id"
                color="accent"
                class="mx-1"
                outlined
                small
                v-if="order.status == 'created'"
                >{{ $t("b4me.edit") }}</v-btn
              >
              <v-btn
                elevation="0"
                @click="cancelOrder(order.id)"
                color="red"
                class="mx-1"
                outlined
                small
                v-if="order.status == 'created'"
                >{{ $t("b4me.cancel") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </v-row>
      <!-- <v-row class="mb-4">
        <v-simple-table fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">{{ $t("account.purchase_#") }}</th>
                <th class="text-center">{{ $t("account.date_requested") }}</th>
                <th class="text-center">{{ $t("account.date_purchased") }}</th>
                <th class="text-center">{{ $t("account.total_purchase") }}</th>
                <th class="text-center">{{ $t("b4me.service_charge") }}</th>
                <th class="text-center">{{ $t("b4me.domestic_charges") }}</th>
                <th class="text-center">{{ $t("b4me.total") }}</th>
                <th class="text-center">{{ $t("account.status") }}</th>
                <th class="text-center"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="order in orders" :key="order.id">
                <td class="text-center">{{ order.id }}</td>
                <td class="text-center">
                  {{ new Date(order.date_requested).toDateString() }}
                </td>
                <td class="text-center">
                  {{ new Date(order.date_purchased).toDateString() }}
                </td>
                <td class="text-center">{{ order.cost_with_currency }}</td>
                <td class="text-center">
                  {{ order.service_charge_with_currency }}
                </td>
                <td class="text-center">
                  {{ order.domestic_charge_with_currency }}
                </td>
                <td class="text-center">{{ order.total_with_currency }}</td>
                <td class="text-center">{{ order.status }}</td>

                <td class="text-center">
                  <div
                    style="
                      display: flex;
                      justify-content: space-around;
                      align-items: center;
                    "
                  >
                    <v-btn
                      :to="'/b4me/pay/' + order.id"
                      color="primary"
                      class="mx-1"
                      small
                      v-if="
                        order.status == 'ready' && order.warba_status == null
                      "
                      >{{ $t("account.ready_to_pay") }}</v-btn
                    >
                    <v-btn
                      :to="'/b4me/' + order.id"
                      color="secondary"
                      class="mx-1"
                      small
                      >{{ $t("b4me.view_details") }}</v-btn
                    >
                    <v-btn
                      :to="'/b4me/track/' + order.id"
                      color="accent"
                      class="mx-1"
                      outlined
                      small
                      >{{ $t("b4me.track") }}</v-btn
                    >
                    <v-btn
                      :to="'/b4me/edit/' + order.id"
                      color="accent"
                      class="mx-1"
                      outlined
                      small
                      v-if="order.status == 'created'"
                      >{{ $t("b4me.edit") }}</v-btn
                    >
                    <v-btn
                      @click="cancelOrder(order.id)"
                      color="red"
                      class="mx-1"
                      outlined
                      small
                      v-if="order.status == 'created'"
                      >{{ $t("b4me.cancel") }}</v-btn
                    >
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-row> -->
    </v-container>
  </div>
</template>

<script>
import Warehouse from "@/components/Warehouse.vue";

export default {
  components: {
    Warehouse,
  },
  name: "Buy4me",
  data: () => ({
    orders: [],
  }),

  created() {
    this.getOrders();
    this.checkPendingList();
  },

  methods: {
    getOrders() {
      this.$http
        .get("/buy4me?store_id=" + this.$store.state.store_id)
        .then(({ data }) => {
          this.orders = data.orders;
        });
    },

    cancelOrder(id) {
      this.$http.delete("/buy4me?id=" + id).then(() => {
        this.getOrders();
      });
    },
    checkPendingList() {
      this.$http.get("/received/warba_pending_list").then(({ data }) => {
        let number = 0;
        number += data.orders.length;
        number += data.buy4me.length;
        this.postPaymentCount(number);
      });
    },
    postPaymentCount(count) {
      if (window.webkit != undefined) {
        if (window.webkit.messageHandlers.pending_payments_count != undefined) {
          console.log("2");
          window.webkit.messageHandlers.pending_payments_count.postMessage(
            count
          );
        }
      }
      if (window.pending_payments_count != undefined) {
        window.pending_payments_count.postMessage(count);
      }
    },
  },
};
</script>

<style scoped>
.v-chip__content {
  color: white;
  height: auto !important;
}
</style>
