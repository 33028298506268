<template>
  <div v-if="order">
    <v-container class="mt-8 mb-16">
      <v-row class="mb-4">
        <p style="font-weight: 700; margin: 0; padding: 0; font-size: 14px">
          {{ $t("account.order_id") }} {{ order.id }}
        </p>
      </v-row>
      <!-- <v-row>
        <p
          style="
            text-$tform: uppercase;
            font-weight: 700;
            font-size: 12px;
            margin-bottom: 8px;
          "
        >
          {{ $t("account.where_would_you") }}
        </p>
      </v-row> -->
      <v-row>
        <!-- <Warehouse /> -->
        <v-text-field
          readonly
          class="mb-4"
          v-model="order.site_name"
          :label="$t('account.site_name')"
          outlined
          hide-details="true"
        ></v-text-field>
        <v-text-field
          readonly
          class="mb-4"
          v-model="order.site_url"
          :label="$t('account.site_url')"
          type="url"
          outlined
          hide-details="true"
        ></v-text-field>
      </v-row>
      <!--
      <v-row>
        <p
          style="
            text-$tform: uppercase;
            font-weight: 700;
            font-size: 12px;
            margin: 8px auto;
          "
        >
          {{ $t("account.please_let_us_know") }}
        </p>
      </v-row> -->

      <!-- <th style="white-space: nowrap" class="text-center">{{$t('b4me.item_name')}}</th>
                                        <th style="white-space: nowrap" class="text-center">{{$t('b4me.options')}}</th>
                                        <th style="white-space: nowrap" class="text-center">{{$t('b4me.item_url')}}</th>
                                        <th style="white-space: nowrap" class="text-center">{{$t('b4me.price_per')}}</th>
                                        <th style="white-space: nowrap" class="text-center">{{$t('b4me.quantity')}}</th>
                                        <th style="white-space: nowrap" class="text-center"></th> -->

      <!-- <div style="width: 100%">
          <v-text-field
            class="my-2"
            v-model="item.name"
            outlined
            hide-details="true"
            :label="$t('b4me.item_name')"
          ></v-text-field>
          <v-text-field
            class="my-2"
            v-model="item.options"
            outlined
            :label="$t('b4me.options')"
            hide-details="true"
          ></v-text-field>
          <v-text-field
            class="my-2"
            v-model="item.url"
            outlined
            :label="$t('b4me.item_url')"
            hide-details="true"
          ></v-text-field>
          <v-text-field
            class="my-2"
            v-model="item.price"
            outlined
            type="number"
            :label="$t('b4me.price_per')"
            hide-details="true"
          ></v-text-field>
          <v-text-field
            class="my-2"
            v-model="item.quantity"
            outlined
            type="number"
            :label="$t('b4me.quantity')"
            hide-details="true"
          ></v-text-field> -->
      <!-- <v-btn class="my-2" outlined color="red"
                      ><v-icon>fa fa-trash</v-icon></v-btn
                    > -->
      <!-- </div>
      </v-row> -->

      <!-- <v-row>
        <v-col cols="12">
          <v-btn @click="addAnotherItem" color="accent" block>
            {{ $t("b4me.add_new_item") }}</v-btn
          >
        </v-col>
      </v-row> -->

      <v-row v-if="order.items.length > 0" class="mt-4">
        <v-expansion-panels>
          <v-expansion-panel v-for="(item, i) in order.items" v-bind:key="i">
            <v-expansion-panel-header>
              {{ item.item_name }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div>
                <v-text-field
                  readonly
                  class="my-2"
                  v-model="item.item_name"
                  outlined
                  hide-details="true"
                  :label="$t('b4me.item_name')"
                ></v-text-field>
                <v-text-field
                  readonly
                  class="my-2"
                  v-model="item.options"
                  outlined
                  :label="$t('b4me.options')"
                  hide-details="true"
                ></v-text-field>
                <v-text-field
                  readonly
                  class="my-2"
                  v-model="item.item_url"
                  outlined
                  :label="$t('b4me.item_url')"
                  hide-details="true"
                ></v-text-field>
                <v-text-field
                  readonly
                  class="my-2"
                  v-model="item.price"
                  outlined
                  type="number"
                  :label="$t('b4me.price_per')"
                  hide-details="true"
                ></v-text-field>
                <v-text-field
                  readonly
                  class="my-2"
                  v-model="item.quantity"
                  outlined
                  type="number"
                  :label="$t('b4me.quantity')"
                  hide-details="true"
                ></v-text-field>
                <!-- <v-btn @click="deleteItem(item.name)" color="error" block
                  ><v-icon>fa fa-trash</v-icon></v-btn
                > -->
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>

      <v-row class="mt-8">
        <v-text-field
          class="mb-4"
          readonly
          v-model="order.domestic_charge"
          :label="$t('b4me.domestic_shipping')"
          :placeholder="$t('b4me.domestic_shipping')"
          outlined
          hide-details="true"
        ></v-text-field>
        <v-textarea
          readonly
          outlined
          name="input-7-4"
          :label="$t('b4me.instructions')"
          hide-details="true"
        ></v-textarea>
      </v-row>
      <v-row class="mt-4">
        <v-col cols="12 px-0">
          <div class="my-0">
            <b>{{ $t("b4me.items_value") }}:</b>
            <span>{{ order.cost_with_currency }}</span>
          </div>
          <div class="my-4">
            <b> {{ $t("b4me.domestic_shipping") }}: </b>
            <span>{{ order.domestic_charge_with_currency }}</span>
          </div>
          <div class="my-4">
            <b> {{ $t("b4me.service_charge") }}: </b>
            <span>{{ order.service_charge_with_currency }}</span>
          </div>
          <div class="my-4">
            <b> {{ $t("b4me.total") }}: </b>
            <span>{{ order.total_with_currency }}</span>
          </div>
        </v-col>
        <v-btn
          elevation="0"
          v-if="order.status == 'created'"
          @click="submitOrder"
          color="accent"
          block
          >{{ $t("b4me.submit") }}</v-btn
        >
      </v-row>
      <!-- <v-row>
        <p
          style="
            text-$tform: uppercase;
            font-weight: 700;
            font-size: 12px;
            margin-bottom: 8px;
          "
        >
          {{ $t("b4me.any_other") }}
        </p>
      </v-row> -->
      <v-row v-if="errors != null">
        <v-col cols="12">
          <v-alert outlined color="error">
            <ul>
              <li v-for="(error, i) in errors" v-bind:key="i">{{ error }}</li>
            </ul>
          </v-alert>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col cols="12">
          <v-btn @click="editOrder" color="primary" block>
            {{ $t("b4me.edit") }}
          </v-btn>
        </v-col>
      </v-row> -->
    </v-container>
  </div>
</template>

<script>
// import Warehouse from "@/components/Warehouse.vue";

export default {
  data: () => ({
    order: null,
    item: {
      name: null,
      options: null,
      url: null,
      price: null,
      quantity: 1,
    },
    errors: null,
  }),
  created() {
    this.getOrders();
  },

  methods: {
    getOrders() {
      this.$http
        .get("/buy4me?store_id=" + this.$store.state.store_id)
        .then(({ data }) => {
          this.order = data.orders.filter((order) => {
            if (order.id == this.$route.params.id) return order;
          })[0];
        });
    },
    submitOrder() {
      this.$http
        .put("/buy4me/submit", { id: this.order.id })
        .then(() => {
          this.$router.push({ name: "B4me" });
        })
        .catch((errors) => {
          this.errors = errors.response.data.errors;
        });
    },
  },
};
</script>
