import Vue from "vue";
import Vuex from "vuex";
// import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    store_id: parseInt(localStorage.getItem('store_id')) || 1,
    token: localStorage.getItem('token') || null,
    // URL: 'http://localhost:8080/api/v1',
    URL: 'https://api.postshipper.com/api/v1',
      // URL: 'http://139.162.212.160:8091/api/v1',
    lang: localStorage.getItem('lang') || 'en',
    notfications: [],
    selectedShippedOrder: null,
    invoice: null,
    user: null,
  },
  getters: {
    loggedIn: (state) => state.token !== null,
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },

    DESTROY_TOKEN(state) {
      state.token = null;
    },

    SET_STORE_ID(state, store_id) {
      state.store_id = store_id;
      },

    SET_NOTFICATIONS(state, notfications) {
      state.notfications = notfications;
    },

    SET_USER(state, user) {
      state.user = user;
    },

    SET_SHIPPED_ORDER(state, selectedShippedOrder) {
      state.selectedShippedOrder = selectedShippedOrder;
    },

    SET_INVOICE(state, invoice) {
      state.invoice = invoice;
    },

    SET_LANG(state, lang) {
      state.lang = lang;
    },
  },
  actions: {
    login({commit}) {
      return commit("SET_TOKEN", localStorage.getItem('token'));
    },

    fetchNotfications({commit}, data) {
          return commit("SET_NOTFICATIONS", data);
    },

    setStore({commit}, data) {
      localStorage.setItem('store_id', data.store_id);
       commit("SET_STORE_ID", data.store_id);
       return location.reload();
    },

    setLang({commit}, lang) {
      localStorage.setItem('lang', lang);
       commit("SET_LANG", lang);
       return location.reload();
    },

    fetchUser({commit}, data) {
          return commit("SET_USER", data);
    },

    setShippedOrder({commit}, data) {
       return commit("SET_SHIPPED_ORDER", data);
    },

    setInvoice({commit}, data) {
       return commit("SET_INVOICE", data);
    },
  },
  modules: {}
});
