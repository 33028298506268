<template>
  <v-container class="my-8">
    <!-- <v-card class="mx-auto mt-2" min-height="450" tile>
    <v-container>
      <v-row>
        <v-col cols="6">
          <div>
            <span style="font-size: 12px">{{$t('account.order_id')}}</span>
            <span
              style="display: block; font-size: 20px"
              class="secondary--text"
              >{{ info.id }}</span
            >
          </div>
        </v-col>
        <v-col cols="6">
          <div>
            <span style="font-size: 12px">{{$t('account.shipped_at')}}</span>
            <span
              style="display: block; font-size: 20px"
              class="secondary--text"
              >{{ new Date(info.created_at).toDateString() }}</span
            >
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <div>
            <span style="font-size: 12px">{{$t('account.order_cost')}}</span>
            <span
              style="display: block; font-size: 20px"
              class="secondary--text"
              >${{ info.duituable_with_currency }}</span
            >
          </div>
        </v-col>
        <v-col cols="6">
          <div>
            <span style="font-size: 12px">{{$t('account.package_count')}}</span>
            <span
              style="display: block; font-size: 20px"
              class="secondary--text"
              >2</span
            >
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-carousel height="235" hide-delimiters show-arrows-on-hover>
      <v-carousel-item v-for="(slide, i) in slides" :key="i">
        <v-img
          :src="info.consolidate_image"
          :lazy-src="info.consolidate_image"
          aspect-ratio="1"
          class="grey lighten-2"
        ></v-img>
      </v-carousel-item>
    </v-carousel>

    <v-card-text>
      <div class="subtitle-1">
        {{ $t('account.order_status') }}:
        <span class="boldnes primary--text">{{ info.status_local }}</span>
      </div>
      <div class="subtitle-1">
        {{ $t('account.order_weight') }}:
        <span class="boldnes primary--text"
          >{{ info.weight }}
          {{ info.store.customizations.weight_unit.code }}</span
        >
      </div>
      <div class="subtitle-1">
        {{ $t('account.shipping_method') }}:
        <span class="boldnes primary--text">{{
          info.courier_og.name_pretty
        }}</span>
      </div>
      <div class="subtitle-1">
        {{$t('account.package_count')}}:
        <span class="boldnes primary--text">2 </span>
      </div>

    </v-card-text>
         <v-card-actions class="pt-0">
          <v-btn
            @click="viewOrder(info)"
            color="primary"
          >
            {{ $t('account.view_order') }}
          </v-btn>
          <v-btn
            :to="'/shipped/order/track/'+info.id"
            color="accent"
          >
             {{ $t('account.track_order') }}
          </v-btn>
        </v-card-actions>
  </v-card> -->

    <v-row>
      <p
        style="
          font-weight: bold;
          font-size: 16px;
          color: #343235;
          padding: 0;
          margin: 0;
        "
      >
        {{ $t("account.nav_orders") }}
      </p>
    </v-row>
    <v-row class="mb-4">
      <v-col v-for="pkg in info.packages" :key="pkg.id" md="3">
        <Package :no_edit="true" :info="pkg" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Package from "@/components/Package.vue";

export default {
  components: {
    Package,
  },
  data: () => ({
    // data props goes here
    overlay: false,
    zIndex: 0,
    tab: null,
    colors: [
      "indigo",
      "warning",
      "pink darken-2",
      "red lighten-1",
      "deep-purple accent-4",
    ],
    slides: ["First", "Second", "Third", "Fourth", "Fifth"],
  }),

  computed: mapState({
    info: (state) => state.selectedShippedOrder,
  }),

  methods: {
    async viewOrder(order) {
      console.log(order);
      await this.$store.dispatch("setShippedOrder", order);
      console.log("seted");
    },
  },
};
</script>

<style lang="scss" scoped>
.boldnes {
  font-weight: 700;
}
</style>
