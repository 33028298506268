<template>
  <div>
    <v-container class="my-8">
      <v-row>
        <p style="font-weight: bold; font-size: 16px; color: #343235">
          {{ $t("account.nav_info") }}
        </p>
      </v-row>
      <v-row>
        <Warehouse />
      </v-row>
      <v-row>
        <p
          v-if="this.$store.state.store_id == 1"
          style="font-weight: 700; font-size: 14px"
        >
          <span v-if="this.$store.state.lang == 'en'"
            >MY US SHIPPING ADDRESS</span
          >
          <span v-if="this.$store.state.lang == 'ar'"
            >عنوان الشحن الامريكي</span
          >
        </p>
        <p
          v-if="this.$store.state.store_id == 2"
          style="font-weight: 700; font-size: 14px"
        >
          <span v-if="this.$store.state.lang == 'en'"
            >MY UK SHIPPING ADDRESS</span
          >
          <span v-if="this.$store.state.lang == 'ar'"
            >عنوان الشحن البريطاني</span
          >
        </p>
      </v-row>
      <v-row v-if="store" class="mt-0">
        <v-col cols="12">
          <div class="my-0" v-if="user">
            <b>{{ $t("account.address") }}: </b>
            <span dir="ltr">{{ store.address_line }} #{{ user.id }}</span>
          </div>
          <div class="my-4">
            <b>{{ $t("account.city") }}:</b> <span>{{ store.city }}</span>
          </div>
          <div class="my-4">
            <b>{{ $t("account.state") }}:</b> <span>{{ store.state }}</span>
          </div>
          <div class="my-4">
            <b>{{ $t("account.postal") }}:</b> <span>{{ store.zip_code }}</span>
          </div>
          <div class="my-4">
            <b>{{ $t("account.country") }}: </b>
            <span>{{ store.country.name_en }}</span>
          </div>
          <div class="my-4">
            <b>{{ $t("account.phone") }}: </b>
            <span dir="ltr">{{ store.phone }}</span>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <p style="font-weight: 700; font-size: 14px">
          {{ $t("account.info_header") }}
        </p>
      </v-row>
      <v-row v-if="store" class="mt-0">
        <v-col cols="12">
          <div class="my-0" v-if="user">
            <span>{{ user.full_name }}</span>
          </div>
          <div class="my-2">
            <span>{{ user.email }}</span>
          </div>
          <div class="my-2">
            <span>{{ user.phone }}</span>
          </div>
          <div class="my-2">
            <span>{{ user.address.country.name_en }}</span>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Warehouse from "@/components/Warehouse.vue";
import { mapState } from "vuex";

export default {
  components: {
    Warehouse,
  },
  data: () => ({
    store: null,
  }),

  computed: mapState({
    user: (state) => state.user,
  }),

  created() {
    this.getStores();
    this.checkPendingList();
  },

  methods: {
    getStores() {
      this.$http.get("/stores").then(({ data }) => {
        this.store = data.stores.filter((store) => {
          if (store.id == this.$store.state.store_id) {
            return store;
          }
        })[0];
      });
    },
    checkPendingList() {
      this.$http.get("/received/warba_pending_list").then(({ data }) => {
        let number = 0;
        number += data.orders.length;
        number += data.buy4me.length;
        this.postPaymentCount(number);
      });
    },
    postPaymentCount(count) {
      if (window.webkit != undefined) {
        if (window.webkit.messageHandlers.pending_payments_count != undefined) {
          console.log("2");
          window.webkit.messageHandlers.pending_payments_count.postMessage(
            count
          );
        }
      }
      if (window.pending_payments_count != undefined) {
        window.pending_payments_count.postMessage(count);
      }
    },
  },
};
</script>
