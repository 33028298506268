<template>
  <div>
    <v-container class="my-8">
      <v-row>
        <p style="font-weight: bold; font-size: 16px; color: #343235">
          {{ $t("account.shipping_calculator") }}
        </p>
      </v-row>
      <v-row>
        <Warehouse />
      </v-row>
      <v-row>
        <p style="font-weight: 700; font-size: 14px">
          {{ $t("account.pkg") }}
        </p>
      </v-row>
      <v-row v-if="store">
        <div style="width: 100%">
          <v-text-field
            class="mb-4"
            v-model="pkg.weight"
            outlined
            :label="
              $t('account.d_weight') +
              '(' +
              $t(
                'account.' +
                  this.store.customizations.weight_unit.code.toLowerCase()
              ) +
              ')'
            "
            hide-details="true"
          ></v-text-field>
          <v-text-field
            class="mb-4"
            v-model="pkg.length"
            outlined
            :label="
              $t('account.d_length') +
              '(' +
              $t(
                'account.' +
                  this.store.customizations.measurement_unit.code.toLowerCase()
              ) +
              ')'
            "
            hide-details="true"
          ></v-text-field>
          <v-text-field
            class="mb-4"
            v-model="pkg.height"
            outlined
            :label="
              $t('account.d_height') +
              '(' +
              $t(
                'account.' +
                  this.store.customizations.measurement_unit.code.toLowerCase()
              ) +
              ')'
            "
            hide-details="true"
          ></v-text-field>
          <v-text-field
            class="mb-4"
            v-model="pkg.width"
            outlined
            :label="
              $t('account.d_width') +
              '(' +
              $t(
                'account.' +
                  this.store.customizations.measurement_unit.code.toLowerCase()
              ) +
              ')'
            "
            hide-details="true"
          ></v-text-field>
          <v-row v-if="packages.length > 0" class="my-6">
            <v-expansion-panels>
              <v-expansion-panel v-for="(item, i) in packages" v-bind:key="i">
                <v-expansion-panel-header>
                  #{{ i + 1 }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div>
                    <v-text-field
                      class="mb-4"
                      v-model="item.weight"
                      outlined
                      :label="$t('account.d_weight')"
                      hide-details="true"
                    ></v-text-field>
                    <v-text-field
                      class="mb-4"
                      v-model="item.length"
                      outlined
                      :label="$t('account.d_length')"
                      hide-details="true"
                    ></v-text-field>
                    <v-text-field
                      class="mb-4"
                      v-model="item.height"
                      outlined
                      :label="$t('account.d_height')"
                      hide-details="true"
                    ></v-text-field>
                    <v-text-field
                      class="mb-4"
                      v-model="item.width"
                      outlined
                      :label="$t('account.d_width')"
                      hide-details="true"
                    ></v-text-field>
                    <v-btn
                      elevation="0"
                      @click="deleteItem(i)"
                      color="error"
                      block
                      ><v-icon>fa fa-trash</v-icon></v-btn
                    >
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
          <v-alert class="px-6" v-if="errors != null" outlined color="error">
            <ul>
              <li v-for="(error, i) in errors" v-bind:key="i">{{ error }}</li>
            </ul>
          </v-alert>
          <v-btn
            :loading="loader"
            @click="addItem"
            class="mb-4"
            color="accent"
            block
          >
            {{ $t("account.add_another_package") }}
          </v-btn>
          <v-btn
            :loading="loader"
            @click="calculate"
            class="mb-4"
            color="accent"
            block
          >
            {{ $t("account.calculate") }}
          </v-btn>
        </div>
      </v-row>
      <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="dialog"
      >
        <template>
          <v-card class="px-4 py-4">
            <div
              v-for="(courier, i) in packages_parsed"
              v-bind:key="i"
              :class="
                $store.state.lang == 'ar' ? 'py-4 text-right' : 'py-4 text-left'
              "
              style="
                display: block;
                font-size: 16px;
                background: transparent !important;
                border-bottom: thin solid rgba(0, 0, 0, 0.38) !important;
                width: 100%;
                text-align: right;
              "
            >
              <div>
                <img
                  style="width: 48px; height: 20px"
                  :src="courier.courier.image_path"
                  :alt="courier.courier.name_pretty"
                  class="mx-1"
                />
                <div class="boldnes" style="font-size: 12px">
                  {{ courier.courier.name_pretty }}
                </div>
                <div style="font-size: 12px">
                  <span class="boldnes">
                    {{ $t("account.delivery_time") }}:
                  </span>
                  {{ $t("front.from") }}
                  {{ courier.courier.working_days_from }}
                  {{ $t("front.to") }}
                  {{ courier.courier.working_days_to }}
                  {{ $t("front.working_days") }}
                </div>
                <div style="font-size: 12px">
                  <span class="boldnes" style="font-size: 12px"
                    >{{ $t("account.shipping_tracking") }}: </span
                  >{{
                    courier.courier.shipment_tracking
                      ? $t("account.yes")
                      : $t("account.no")
                  }}
                </div>
                <div style="font-size: 12px">
                  <span class="boldnes" style="font-size: 12px"
                    >{{ $t("account.charged_weight") }}: </span
                  >{{
                    courier.weights.total_charged_weight +
                    store.customizations.weight_unit.code
                  }}
                </div>
                <div style="font-size: 12px">
                  <span class="boldnes" style="font-size: 12px"
                    >{{ $t("account.cost") }}: </span
                  >{{ courier.price.toFixed(3) }} {{ courier.currency }}
                </div>
              </div>
            </div>
            <v-card-actions>
              <v-btn color="accent" block outlined @click="dialog = false">{{
                $t("account.alert_done")
              }}</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import Warehouse from "@/components/Warehouse.vue";

export default {
  components: {
    Warehouse,
  },
  data: () => ({
    store: null,
    dialog: false,
    errors: null,
    loader: false,
    packages: [],
    packages_parsed: null,
    pkg: {
      weight: 0,
      height: 0,
      width: 0,
      length: 0,
    },
  }),

  created() {
    this.getStores();
    this.checkPendingList();
  },

  methods: {
    addItem() {
      console.log("gg");
      this.loader = true;
      this.packages.push(this.pkg);
      this.pkg = {
        weight: 0,
        height: 0,
        width: 0,
        length: 0,
      };
      this.loader = false;
    },
    deleteItem(itemIndex) {
      this.packages = this.packages.filter((p, i) => {
        return itemIndex != i;
      });
    },
    getStores() {
      this.$http.get("/stores").then(({ data }) => {
        this.store = data.stores.filter((store) => {
          if (store.id == this.$store.state.store_id) {
            return store;
          }
        })[0];
      });
    },

    calculate() {
      this.loader = true;
      let pkges = [];
      if (this.packages.length == 0) {
        pkges.push(this.pkg);
      } else {
        this.packages.push(this.pkg);
        pkges = this.packages;
      }

      pkges.forEach((element) => {
        element.dimensions_unit =
          this.store.customizations.measurement_unit.code.toLowerCase();
        element.unit_weight =
          this.store.customizations.weight_unit.code.toLowerCase();
      });
      this.$http
        .post("/calculate", {
          store_id: this.store.id,
          country_code: "KW",
          packages: pkges,
        })
        .then((res) => {
          this.packages_parsed = res.data.costs;
          this.loader = false;
          this.dialog = true;
        })
        .catch((errors) => {
          this.loader = false;
          this.errors = errors.response.data.errors;
        });
    },
    converterR(price) {
      this.$http
        .post("/received/convert_to_kw_store", {
          store_id: this.store.id,
          amounts: [price],
        })
        .then(({ data }) => {
          console.log(data);
          return data[0];
        });
    },
    checkPendingList() {
      this.$http.get("/received/warba_pending_list").then(({ data }) => {
        let number = 0;
        number += data.orders.length;
        number += data.buy4me.length;
        this.postPaymentCount(number);
      });
    },
    postPaymentCount(count) {
      if (window.webkit != undefined) {
        if (window.webkit.messageHandlers.pending_payments_count != undefined) {
          console.log("2");
          window.webkit.messageHandlers.pending_payments_count.postMessage(
            count
          );
        }
      }
      if (window.pending_payments_count != undefined) {
        window.pending_payments_count.postMessage(count);
      }
    },
  },
};
</script>
