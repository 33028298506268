<template>
  <div>
    <v-container class="my-8">
      <v-row>
        <p style="font-weight: bold; font-size: 16px; color: #343235">
          {{ $t("account.addresses") }}
        </p>
      </v-row>
      <v-row>
        <Warehouse />
      </v-row>
      <v-row>
        <p
          v-if="this.$store.state.store_id == 1"
          style="font-weight: 700; font-size: 14px"
        >
          <span v-if="this.$store.state.lang == 'en'"
            >MY US SHIPPING ADDRESS</span
          >
          <span v-if="this.$store.state.lang == 'ar'"
            >عنوان الشحن الامريكي</span
          >
        </p>
        <p
          v-if="this.$store.state.store_id == 2"
          style="font-weight: 700; font-size: 14px"
        >
          <span v-if="this.$store.state.lang == 'en'"
            >MY UK SHIPPING ADDRESS</span
          >
          <span v-if="this.$store.state.lang == 'ar'"
            >عنوان الشحن البريطاني</span
          >
        </p>
      </v-row>
      <v-row v-if="store" class="mt-0">
        <v-col cols="12">
          <div class="my-0" v-if="user">
            <b>{{ $t("account.address") }}1: </b>
            <span dir="ltr"
              >{{ store.address_line }}
              {{
                store.customizations.mailbox_position == "address_line1"
                  ? "#" + user.id
                  : ""
              }}</span
            >
          </div>
          <div class="my-0" v-if="user">
            <b>{{ $t("account.address") }}2: </b>
            <span dir="ltr"
              >{{ store.address_line2 }}
              {{
                store.customizations.mailbox_position == "address_line2"
                  ? "#" + user.id
                  : ""
              }}</span
            >
          </div>
          <div class="my-4">
            <b>{{ $t("account.city") }}:</b> <span>{{ store.city }}</span>
          </div>
          <div class="my-4">
            <b>{{ $t("account.state") }}:</b> <span>{{ store.state }}</span>
          </div>
          <div class="my-4">
            <b>{{ $t("account.postal") }}:</b> <span>{{ store.zip_code }}</span>
          </div>
          <div class="my-4">
            <b>{{ $t("account.country") }}: </b>
            <span>{{ store.country.name_en }}</span>
          </div>
          <div class="my-4">
            <b>{{ $t("account.phone") }}: </b>
            <span dir="ltr">{{ store.phone }}</span>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <p style="font-weight: 700; font-size: 14px">
          {{ $t("account.my_address") }}
        </p>
      </v-row>
      <v-row v-if="user">
        <div style="width: 100%">
          <v-text-field
            class="my-2"
            v-model="user.address.address_1"
            outlined
            :label="$t('account.address')"
            hide-details="true"
          ></v-text-field>
          <v-text-field
            class="my-2"
            v-model="user.address.address_2"
            outlined
            :label="$t('account.address_2')"
            hide-details="true"
          ></v-text-field>
          <v-text-field
            class="my-2"
            v-model="user.address.city"
            outlined
            :label="$t('account.city')"
            hide-details="true"
          ></v-text-field>
          <v-text-field
            class="my-2"
            v-model="user.address.state"
            outlined
            :label="$t('account.state')"
            hide-details="true"
          ></v-text-field>
          <v-text-field
            class="my-2"
            v-model="user.address.postal"
            outlined
            :label="$t('account.postal')"
            hide-details="true"
          ></v-text-field>
          <v-text-field
            class="my-2"
            v-model="user.address.phone"
            outlined
            :label="$t('account.phone')"
            hide-details="true"
          ></v-text-field>
          <v-alert class="px-6" v-if="errors != null" outlined color="error">
            <ul>
              <li v-for="(error, i) in errors" v-bind:key="i">{{ error }}</li>
            </ul>
          </v-alert>
          <v-btn
            :loading="loader"
            @click="updateAddress"
            class="my-2"
            color="accent"
            block
          >
            {{ $t("account.update") }}
          </v-btn>
        </div>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Warehouse from "@/components/Warehouse.vue";
import { mapState } from "vuex";

export default {
  components: {
    Warehouse,
  },
  data: () => ({
    store: null,
    errors: null,
    loader: false,
  }),

  computed: mapState({
    user: (state) => state.user,
  }),

  created() {
    this.getStores();
    this.checkPendingList();
  },

  methods: {
    getStores() {
      this.$http.get("/stores").then(({ data }) => {
        this.store = data.stores.filter((store) => {
          if (store.id == this.$store.state.store_id) {
            return store;
          }
        })[0];
      });
    },

    updateAddress() {
      this.loader = true;
      this.user.address.address_id = this.user.address.id;
      this.user.address.address_name = this.user.address.name;
      this.user.address.address = this.user.address.address_1;
      this.$http
        .patch("/auth/update_address", this.user.address)
        .then(() => {
          console.log("updated");
          this.loader = false;
        })
        .catch((errors) => {
          this.loader = false;
          this.errors = errors.response.data.errors;
        });
    },
    checkPendingList() {
      this.$http.get("/received/warba_pending_list").then(({ data }) => {
        let number = 0;
        number += data.orders.length;
        number += data.buy4me.length;
        this.postPaymentCount(number);
      });
    },
    postPaymentCount(count) {
      if (window.webkit != undefined) {
        if (window.webkit.messageHandlers.pending_payments_count != undefined) {
          console.log("2");
          window.webkit.messageHandlers.pending_payments_count.postMessage(
            count
          );
        }
      }
      if (window.pending_payments_count != undefined) {
        window.pending_payments_count.postMessage(count);
      }
    },
  },
};
</script>
