<template>
  <v-app>
    <Header v-if="$route.meta.requiresAuth" />

    <div style="width: 100%; padding: 0 16px; margin: 0 auto">
      <v-main>
        <router-view></router-view>
      </v-main>
    </div>

    <div class="overflow-hidden mx-1 mt-8" v-if="$route.meta.requiresAuth">
      <v-bottom-navigation
        style="height: 64px; padding-left: 10px; padding-right: 10px"
        v-model="value"
        color="transparent"
        fixed
        grow
      >
        <v-btn @click="value = 0" to="/">
          <span
            v-if="value == 0"
            style="color: #0070eb; font-size: 10px; margin-top: 8px"
            >{{ $t("account.nav_packages") }}</span
          >

          <span v-if="value != 0" style="font-size: 10px; margin-top: 8px">{{
            $t("account.nav_packages")
          }}</span>
          <img
            v-if="value != 0"
            src="./assets/Icons/received_packages/received_packages.svg"
          />
          <img
            v-if="value == 0"
            src="./assets/Icons/received_packages/received_packages_blue.svg"
          />
        </v-btn>

        <v-btn @click="value = 1" to="/invoices">
          <span
            v-if="value == 1"
            style="color: #0070eb; font-size: 10px; margin-top: 8px"
            >{{ $t("account.nav_invoice") }}</span
          >
          <span v-if="value != 1" style="font-size: 10px; margin-top: 8px">{{
            $t("account.nav_invoice")
          }}</span>
          <img v-if="value != 1" src="./assets/Icons/invoices/invoices.svg" />
          <img
            v-if="value == 1"
            src="./assets/Icons/invoices/invoices_blue.svg"
          />
        </v-btn>
        <v-btn @click="value = 2" to="/b4me">
          <span v-if="value != 2" style="font-size: 10px; margin-top: 8px">{{
            $t("account.assisted_purchase")
          }}</span>
          <span
            v-if="value == 2"
            style="color: #0070eb; font-size: 10px; margin-top: 8px"
            >{{ $t("account.assisted_purchase") }}</span
          >
          <img v-if="value != 2" src="./assets/Icons/buy4me/buy4me.svg" />
          <img v-if="value == 2" src="./assets/Icons/buy4me/buy4me_blue.svg" />
        </v-btn>

        <v-btn @click="value = 3" to="/shipped">
          <span v-if="value != 3" style="font-size: 10px; margin-top: 8px">{{
            $t("account.nav_orders")
          }}</span>
          <span
            v-if="value == 3"
            style="color: #0070eb; font-size: 10px; margin-top: 8px"
            >{{ $t("account.nav_orders") }}</span
          >
          <img
            v-if="value != 3"
            src="./assets/Icons/shipped_packages/shipped_packages.svg"
          />
          <img
            v-if="value == 3"
            src="./assets/Icons/shipped_packages/shipped_packages_blue.svg"
          />
        </v-btn>
      </v-bottom-navigation>
    </div>
    <!-- <v-footer> All Rights Reserved @Postshipper </v-footer> -->
  </v-app>
</template>

<script>
import Header from "./components/Layout/Header.vue";
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "App",

  components: {
    Header,
  },

  data: () => ({
    value: 0,
    active: true,
    navItems: [
      {
        title: "Home",
      },
      {
        title: "Shipping Cost",
      },
      {
        title: "How It Works",
      },
      {
        title: "Services",
      },
      {
        title: "About",
      },
      {
        title: "FAQ",
      },
      {
        title: "Contact Us",
      },
    ],
  }),

  computed: mapState({
    lang: (state) => state.lang,
  }),

  created() {
    this.$i18n.locale = this.lang;
    axios.defaults.headers.common["Accept-Language"] = this.$i18n.locale;

    if (this.lang == "ar") {
      this.$vuetify.rtl = true;
    } else {
      this.$vuetify.rtl = false;
    }
  },
};
</script>

<style lang="scss">
// $primary: '#E76A2B',
// $secondary: '#21aea7',
// $accent: '#2a9fd8'

@font-face {
  font-family: "BrownStd";
  src: url("./assets/fonts/BrownStd-Regular.otf");
}

@font-face {
  font-family: "GE Dinar";
  src: url("./assets/fonts/GE Dinar Two Medium.otf");
}

* {
  font-family: BrownStd, GE Dinar, sans-serif;
  color: #343235;
  letter-spacing: initial;
  text-transform: none;
  text-transform: capitalize;
}

// *::first-letter {
//   text-transform: capitalize;
// }

.panel-step {
  font-size: 20px;
  line-height: 1;
}

.boldnes {
  font-weight: 700;
}
</style>
