<template>
  <div class="py-2 pt-4" style="border-bottom: 1px solid rgba(0, 0, 0, 0.12)">
    <v-toolbar dense flat>
      <div style="margin: 0; padding: 0">
        <img
          src="https://www.postshipper.com/client/images/logo.png"
          width="135"
          alt="postshipper"
          @click="changeLang('en')"
        />
      </div>
      <v-spacer></v-spacer>
      <!-- <img
        style="margin: 0 -6px"
        width="18"
        v-if="$i18n.locale == 'en'"
        @click="changeLayout('ar')"
        src="../../assets/Icons/arabic.svg"
        alt="ar"
      />
      <img
        style="margin: 0 -6px"
        width="18"
        v-if="$i18n.locale == 'ar'"
        @click="changeLayout('en')"
        src="../../assets/Icons/english.svg"
        alt="en"
      /> -->

      <!-- <select @change="changeLayout($i18n.locale)" v-model="$i18n.locale">
        <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
          {{ lang }} - <img src="../../assets/Icons/arabic.svg" alt="" />
        </option>
      </select> -->
      <v-btn class="mx-2" to="/notfications" icon>
        <v-badge color="red" overlap :content="notficationsCount">
          <img src="../../assets/Icons/bell.svg" alt="not" />
        </v-badge>
      </v-btn>
      <v-menu bottom :close-on-click="closeOnClick" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <!-- <v-btn color="iconsHeader" dark v-bind="attrs" v-on="on" icon> -->
          <!-- <v-icon dark v-bind="attrs" v-on="on" color="iconsHeader"
            >fas fa-bars</v-icon
          > -->
          <img
            v-bind="attrs"
            v-on="on"
            src="../../assets/Icons/menu.svg"
            alt="|||"
          />

          <!-- </v-btn> -->
        </template>

        <v-list>
          <!-- <v-list-item v-for="(item, index) in items" :key="index"> -->
          <!-- <v-list-item>
            <v-btn text to="/">
              <span style="font-size: 10px">{{
                $t("account.nav_packages")
              }}</span>
            </v-btn>
          </v-list-item>

          <v-list-item>
            <v-btn text to="/invoices">
              <span style="font-size: 10px">{{
                $t("account.nav_invoice")
              }}</span>
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn text to="/shipped">
              <span style="font-size: 10px">{{
                $t("account.nav_orders")
              }}</span>
            </v-btn>
          </v-list-item> -->
          <v-list-item>
            <v-btn text to="/account">
              <span style="font-size: 14px">{{ $t("account.nav_info") }}</span>
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn text to="/address">
              <span style="font-size: 14px">{{ $t("account.addresses") }}</span>
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn text to="/calculator">
              <span style="font-size: 14px">{{
                $t("account.shipping_calculator")
              }}</span>
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn text to="/faq">
              <span style="font-size: 14px">{{ $t("account.faq") }}</span>
            </v-btn>
          </v-list-item>
          <v-list-item v-if="user">
            <v-btn text disabled>
              <span style="font-size: 14px">{{ $t("account.credits") }} </span>
              <v-chip class="mx-1" small>
                ${{ user.credits_dollar }}</v-chip
              ></v-btn
            >
          </v-list-item>
        </v-list>
      </v-menu>
      <div
        class="hidden-md-and-down mt-3"
        style="display: flex; align-items: center"
      >
        <v-btn
          v-for="item in navItems"
          :key="item.title"
          text
          medium
          color="secondary"
        >
          {{ item.title }}
        </v-btn>
        <v-badge overlap left color="red" content="6">
          <v-btn tile color="secondary">
            <v-icon>fas fa-bell</v-icon>
          </v-btn>
        </v-badge>
        <v-btn style="height: 36px" class="mx-1" tile color="primary"
          >My Account</v-btn
        >
      </div>
    </v-toolbar>
    <!-- <v-divider></v-divider> -->
  </div>
</template>

<script>
export default {
  data: () => ({
    notficationsCount: 0,
    user: null,
    items: [
      { title: "Click Me" },
      { title: "Click Me" },
      { title: "Click Me" },
      { title: "Click Me 2" },
    ],
    langs: ["ar", "en"],
    closeOnClick: true,
    navItems: [
      {
        title: "Home",
      },
      {
        title: "Shipping Cost",
      },
      {
        title: "How It Works",
      },
      {
        title: "Services",
      },
      {
        title: "About",
      },
      {
        title: "FAQ",
      },
      {
        title: "Contact Us",
      },
    ],
  }),
  created() {
    this.notfication();
    this.userInfo();
  },

  methods: {
    notfication() {
      this.$http
        .get("/notifications?store_id=" + this.$store.state.store_id)
        .then(({ data }) => {
          this.$store.dispatch("fetchNotfications", data.unread);
          this.notficationsCount = data.unread.length;
        });
    },

    userInfo() {
      this.$http.get("/auth/user_info").then(({ data }) => {
        this.$store.dispatch("fetchUser", data.user);
        this.user = data.user;
      });
    },

    changeLayout(lang) {
      console.log("lang");
      this.$store.dispatch("setLang", lang);
      // if(lang == 'ar'){
      //           this.$vuetify.rtl = true
      // }else{
      //           this.$vuetify.rtl = false

      // }
    },

    changeLang(lang) {
      this.$i18n.locale = lang;
      this.changeLayout(lang);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-toolbar__content {
  padding: 0 !important;
}

.v-toolbar__extension {
  padding: 0px 16px 0px 0px;
}
</style>
